body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
  max-height: 100vh;
  max-width: 100vw;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.hd-attachments-container {
  position: absolute;
  bottom: 0;
  display: none;
  background-color: #0062B2;
  flex-direction: column;
  align-items: center;
}

.hd-attachments-container.active {
  display: flex;
  --animate-duration: 200ms;
}

.hd-attachments-toolbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 6px;
}

.video-call-container {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  max-height: 100%;
  flex: 1;
  background-color: #00070d;
  border: 0px teal solid;
  padding: 2px;
}

.local-video-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ecf7ff;
  border: 0px white solid;
  overflow: hidden;
  padding: 4px;
  margin-bottom: 4px;
  border-radius: 6px;
}
